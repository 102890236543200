import React from "react";
import LanguagePicker from "./language-picker";
function Logo() {
  return (
    <button
      type={"button"}
      className={"logo"}
      onClick={() => window.scrollTo(0, 0)}
    >
      <img alt={"SUNA - LOGO"} src={"/assets/images/logo.svg"} />
      <span>SUNA</span>
    </button>
  );
}
function Header() {
  return (
    <div className={"main-header"}>
      <Logo />
      <LanguagePicker />
    </div>
  );
}
export default React.memo(Header);
